<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="modal-inner-content">
      <div class="d-flex p-3 mx-auto align-items-cemter justify-content-center">
        <div><img class="img" src="/hubert-waving.png" /></div>
        <div class="d-flex text-primary flex-column mx-auto align-items-cemter justify-content-center">
          <div class="title">You did it!</div>
          <div><small>Entry submitted successfully</small></div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button @click="dismissModal">OK</ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['close', 'ok']);
const isOpen = toRef(props, 'isOpen');

const dismissModal = () => {
  emits('close');
};
</script>
<style lang="sass" scoped>
.img
   height: 250px
   width: auto
   object-fit: cover
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #f2f2f2)
  max-height: 80vh
  overflow-x: hidden
  overflow-y: auto

.modal-big
  --width: 500px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  font-size: 32px
  font-weight: bold
</style>
