<template>
  <div class="infinite-public-submissions">
    <div class="d-flex align-items-center justify-content-center padding-filter pb-2">
      <h4 v-if="!!submissions?.length" class="">
        <b style="color: #ae38e5"> Check out these Creators! ({{ submissionsCount }})</b>
      </h4>
      <div class="ml-auto">
        <multiselect
          v-model="selectedOption"
          class="choose-options"
          placeholder="Filter content"
          select-label=""
          deselect-label=""
          :options="options"
          :multiple="false"
          :taggable="false"
          :loading="isLoading"
          :disabled="isLoading"
          open-direction="bottom"
          :close-on-select="true"
          :searchable="false"
          track-by="value"
          label="name"
        >
          <template #noOptions>Nothing to select</template>
        </multiselect>
      </div>
    </div>
    <div v-if="isLoading && !submissions.length" class="d-flex justify-content-center">
      <ChLoading size="sm" class="spinner" />
    </div>

    <div class="list-container">
      <div
        v-for="(sub, index) in submissionsToPresent"
        :key="index"
        class="card-wrapper mb-4"
        :class="{ 'mr-2': index % 3 !== 2 }"
      >
        <SubmissionCard
          :submission="sub"
          :on-press="openModal"
          :left-indent-popup="index % 3 === 0"
          :right-indent-popup="index % 3 === 2"
          @reaction="onReaction"
        />
      </div>
    </div>
    <div class="list-container-mob">
      <div
        v-for="(sub, index) in submissionsToPresent"
        :key="index"
        class="card-wrapper mb-4"
        :class="{ 'mr-2': index % 2 !== 1 }"
      >
        <SubmissionCard
          :submission="sub"
          :on-press="openModal"
          :left-indent-popup="index % 2 === 0"
          :right-indent-popup="index % 2 === 1"
          @reaction="onReaction"
        />
      </div>
    </div>
    <div class="text-center">
      <ion-button v-if="paging?.next && submissions.length" @click="nextPage"> Load more </ion-button>
    </div>
    <VisualPreviewModal :image-url="selectedImageUrl" :is-open="imageModalIsOpen" @dismiss-modal="closeImageModal" />
    <TextPreviewModal :text="selectedText" :is-open="textModalIsOpen" @dismiss-modal="closeTextModal" />
  </div>
</template>

<script lang="ts" setup>
import Multiselect from 'vue-multiselect';
import SubmissionCard from './SubmissionCardPreview.vue';
import { SiteEventTaskSubmission, Paging } from '@/shared/types/static-types';
import { getPublicTaskSubmissions, getNextPage, getPublicTaskSubmissionsFilters } from '@/shared/actions/events';
import VisualPreviewModal from '@/shared/modals/VisualPreviewModal.vue';
import TextPreviewModal from '@/shared/modals/TextPreviewModal.vue';

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  submissionIdToFetch: {
    type: String,
    required: false,
  },
});
const emits = defineEmits(['toggleHeader']);
const taskId = toRef(props, 'taskId');
const submissionIdToFetch = toRef(props, 'submissionIdToFetch');
const paging = ref<Paging>({} as any);
const submissions = ref<any[]>([]);
const submissionsCount = ref(0);

const selectedOption = ref({ name: 'Top', value: '-reaction_count' });
const isLoading = ref(false);
const options = ref([
  { name: 'Top', value: '-reaction_count' },
  { name: 'Latest', value: '-created' },
]);
const imageModalIsOpen = ref(false);
const selectedImageUrl = ref('');
const openImageModal = (url: string) => {
  selectedText.value = '';
  textModalIsOpen.value = false;
  selectedImageUrl.value = url;
  imageModalIsOpen.value = true;
};
const closeImageModal = () => {
  selectedImageUrl.value = '';
  imageModalIsOpen.value = false;
};

const textModalIsOpen = ref(false);
const selectedText = ref('');
const openTextModal = (text: string) => {
  selectedImageUrl.value = '';
  imageModalIsOpen.value = false;
  selectedText.value = text;
  textModalIsOpen.value = true;
};
const closeTextModal = () => {
  selectedText.value = '';
  textModalIsOpen.value = false;
};

const openModal = (submission: SiteEventTaskSubmission) => {
  if (!submission) return;
  const router = useRouter();
  if (submission.submission_image_url) {
    openImageModal(submission.submission_image_url);
  } else if (submission.submission_character) {
    router.push(`/character/${submission.submission_character.slug}`);
  } else if (submission.submission_text) {
    openTextModal(submission.submission_text);
  }
};

watch(selectedOption, () => {
  submissions.value = [];
  fetchSubmissions();
});

watch(taskId, async (newTaskId) => {
  submissions.value = [];
  if (!newTaskId) {
    return;
  }
  paging.value = await getPublicTaskSubmissions(newTaskId, submissionIdToFetch.value);
  submissions.value = paging.value.results || [];
  emits('toggleHeader', !!submissions.value?.length);
});

const submissionsToPresent = computed(() => {
  return submissions.value.flatMap((submission) => {
    if (submission.submission_characters.length > 1) {
      return submission.submission_characters.map((ch: any) => {
        return { ...submission, submission_character: ch };
      });
    } else {
      if (submission.submission_characters.length === 1 && !submission.submission_character) {
        return { ...submission, submission_character: submission.submission_characters[0] };
      }
      return submission;
    }
  });
});

onMounted(async () => {
  if (!taskId.value) {
    return;
  }
  await fetchPublicTaskSubmissionsFilters(taskId.value);
});

const fetchSubmissions = async () => {
  isLoading.value = true;
  paging.value = await getPublicTaskSubmissions(taskId.value, submissionIdToFetch.value, {
    ...(selectedOption.value?.value && { ordering: selectedOption.value?.value }),
  });
  submissions.value = paging.value.results || [];
  submissionsCount.value = paging.value.count;
  isLoading.value = false;
  emits('toggleHeader', !!submissions.value?.length);
};

const fetchPublicTaskSubmissionsFilters = async (taskId: string) => {
  const res = await getPublicTaskSubmissionsFilters(taskId);
  selectedOption.value =
    options.value.find((option) => {
      return option.value === res?.sorting;
    }) || options.value[0];
};

const nextPage = async () => {
  if (!paging.value?.next) {
    return;
  }
  paging.value = await getNextPage(paging.value);
  submissions.value = submissions.value.concat(paging.value.results || []);
};

const onReaction = (event: { reaction: string; reactionId: string; submission: SiteEventTaskSubmission }) => {
  const s = submissions.value.find((s) => s.id === event.submission?.id);
  if (!s) {
    return;
  }
  if (s.user_reaction && event.reactionId) {
    // editing reaction type
    //  @ts-ignore
    s?.reaction_counts && s.reaction_counts[s.user_reaction.reaction as keyof ReactionCounts]--;
    //  @ts-ignore
    if (s.reaction_counts[event.reaction]) {
      //  @ts-ignore
      s.reaction_counts[event.reaction as keyof ReactionCounts]++;
    } else {
      //  @ts-ignore
      s.reaction_counts[event.reaction as keyof ReactionCounts] = 1;
    }
  }
  if (!s.user_reaction && event.reactionId) {
    // adding reaction
    //  @ts-ignore
    if (s.reaction_counts[event.reaction]) {
      //  @ts-ignore
      s.reaction_counts[event.reaction as keyof ReactionCounts]++;
    } else {
      //  @ts-ignore
      s.reaction_counts[event.reaction as keyof ReactionCounts] = 1;
    }

    //  @ts-ignore
    s.reaction_counts.total_count++;
  }
  if (s.user_reaction && !event.reactionId) {
    // removing reaction
    //  @ts-ignore
    s.reaction_counts[s.user_reaction.reaction as keyof ReactionCounts]--;
    //  @ts-ignore
    s.reaction_counts.total_count--;
    //  @ts-ignore
    s.user_reaction = null;
  }
  //  @ts-ignore
  if (event.reactionId) s.user_reaction = { id: event.reactionId, ...s.user_reaction, reaction: event.reaction };
  else s.user_reaction = undefined;
};
</script>

<style lang="sass" scoped>
.infinite-public-submissions
  height: 100% !important
  overflow-x: hidden
  overflow-y: hidden
.list-container
  width: 100% !important
  display: none
.list-container-mob
  display: block

.padding-filter
  padding-right: 1rem
  @media(max-width: 768px)
    padding-right: 0.25rem
.card-wrapper
  display: inline-block
  width: 32%

@media(max-width: 768px)
  .card-wrapper
    width: 48%

@media(min-width: 768px)
  .list-container
    display: block
  .list-container-mob
    display: none

.choose-options
  width: 200px
  @media(max-width: 370px)
    width: 100px
  @media(max-width: 250px)
    width: 90px
::v-deep
  .multiselect--active .multiselect__select
    transform: rotateZ(0deg) !important
    border-bottom-right-radius: 0px !important

  .multiselect--active .multiselect__tags
    border-bottom-left-radius: 0px !important
    border-bottom-right-radius: 0px !important

::v-deep .multiselect
  min-height: 0px !important

  .multiselect__tags
    border-radius: 12px
    border: 3px solid var(--ion-color-primary)
    height: 34px
    min-height: 34px !important
    padding: 5px 40px 0 8px
  .multiselect__content-wrapper
    border-radius: 15px !important
    border-top-left-radius: 0px !important
    border-top-right-radius: 0px !important

  .multiselect__select
    height: 32px !important
    background: var(--ion-color-primary)
    width: 35px !important
    border-top-right-radius: 15px
    border-bottom-right-radius: 15px
    line-height: 16px !important
    right: 0px
  .multiselect__select::before
    border-color: #fff transparent transparent transparent !important
  .multiselect__single
    line-height: 20px !important
  .multiselect__placeholder
    padding-top: 0px
  .multiselect__spinner
    height: 32px !important
    background: var(--ion-color-primary)
    width: 35px !important
    border-top-right-radius: 15px
    border-bottom-right-radius: 15px
    line-height: 16px !important
  .multiselect__spinner:before, .multiselect__spinner:after
    border-color: #ffffff transparent transparent !important
</style>
