<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3 py-2">
          <div class="title">Share Entry</div>
          <div></div>
          <div>
            <ion-buttons slot="end">
              <ion-button @click="closeModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content p-3 text-black">
      <div class="d-flex flex-column justify-content-center text-center">
        <div class="tagline">{{ tagline }}</div>
        <div class="p-2">
          <template v-if="submissionText">
            {{ submissionText }}
          </template>
          <template v-else-if="submissionCharacter">
            <img class="event-img" :src="get(submissionCharacter, 'info.cropProfilePicture')" />
          </template>
          <template v-else-if="submissionImage">
            <img class="event-img" :src="submissionImage" />
          </template>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  task: {
    type: Object,
    default: {},
  },
  submission: {
    type: Object,
    default: {},
  },
});

const submission = toRef(props, 'submission');
const task = toRef(props, 'task');
const emits = defineEmits(['close', 'dismiss']);
const tagline = computed(() => {
  return get(task.value, 'event.share_entry_tagline')
    ? get(task.value, 'event.share_entry_tagline')
    : 'Tag @Characterhub for a shoutout!';
});

const submissionText = computed(() => {
  return get(submission.value, 'submission_text');
});
const submissionCharacter = computed(() => {
  return get(submission.value, 'submission_character');
});
const submissionImage = computed(() => {
  return get(submission.value, 'submission_image_url');
});

const dismissModal = (val: any) => {
  emits('dismiss', val);
};

const closeModal = () => {
  emits('close');
};
</script>
<style lang="sass" scoped>
.event-img
  height: 250px
  width: 250px
  border-radius: 6px
  object-fit: contain
.link
  text-decoration: underline
  font-size: 16px
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #f2f2f2)
  max-height: 60vh
  overflow-x: hidden
  overflow-y: auto

.modal-big
  --width: 500px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  font-size: 22px
  font-weight: bold
.tagline
  font-size: 18px
</style>
