<template>
  <div>
    <div class="d-flex align-items-center justify-content-center" v-if="isLoading">
      <ChLoading size="lg" />
    </div>
    <div v-else>
      <div class="position-relative slide d-flex justify-content-center mx-auto align-items-center ">
        <div class="prompts position-absolute">{{ extractFirstTxt }}</div>

        <ClientOnly>
          <img loading="lazy" v-image :src="get(taskData, 'event_image_url')" class="slide-img position-relative" />
          <div class="d-flex flex-column quest-sec">
            <div class="quest-title">QUEST:</div>
            <div class="task-title">{{ truncateEasy(taskData.name, 100) }}</div>
          </div>
        </ClientOnly>

      </div>
      <ion-page id="characters-page" class="page scrollable bg-transparent events-preview">
        <p v-if="taskData?.event_slug" class="mb-2">
          <router-link :to="{ name: 'events' }">
            <span class="small clickable-item-hov event-clr">
              See all Events
            </span>
          </router-link>
          <span class="small clickable-item-hov event-clr"> > </span>
          <router-link :to="{ name: 'event-details-preview', params: { slug: taskData.event_slug } }">
            <span class="small clickable-item-hov event-clr">{{ taskData.event_title }}</span>
          </router-link>
          <span class="small clickable-item-hov event-clr"> > </span>
          <router-link to="#">
            <span class="small clickable-item-hov event-clr">{{ taskData.name }}</span>
          </router-link>
        </p>
        <div class="p-3 event-task-preview">
          <h1 class="title py-0 my-0 text-primary">{{ taskData.name }}</h1>
          <div class="my-3">
            <p v-if="taskStatus === 'expired'">Submission period has ended</p>
            <ion-button v-else-if="taskData.starts_at || taskData.ends_at" class="end-btn mx-0"
              :class="{ 'text-font': showRefreshTimer(taskData) }"
              :color="dateHasPassed(taskData.starts_at) ? (showRefreshTimer(taskData) ? 'tertiary' : 'primary') : 'medium'">
              <i class="ti-time icon" />
              <div v-if="dateHasPassed(taskData.starts_at)">
                <vue-countdown v-if="computedEndsAt()" v-slot="{ days, hours, minutes, seconds }"
                  :time="formatFromNowInMilliseconds(computedEndsAt())">
                  <span><span v-if="showRefreshTimer(taskData)">Restarts in </span><span v-else>Ends in </span><span
                      v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span><span v-if="!days">{{
                        minutes
                      }}m
                    </span><span v-if="!days && !hours">{{ seconds }}s</span></span>
                </vue-countdown>
              </div>

              <div v-else>
                <!-- Upcoming -->
                <vue-countdown v-if="taskData?.starts_at" v-slot="{ days, hours, minutes, seconds }"
                  :time="formatFromNowInMilliseconds(taskData.starts_at)">
                  <span>Starts in <span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span><span
                      v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span></span>
                </vue-countdown>
              </div>
            </ion-button>
          </div>
          <div v-if="taskData.description" class="d-flex align-items-center mt-2">
            <i class="ti-info prewrap-icon" />
            <strong class="text-primary" style="font-size: 22px;">How to participate?</strong>
          </div>
          <div v-if="taskData.description" class="prewrap mb-3 mt-2" v-html="taskData.description" />
          <div v-if="!submitDisabled && taskData?.name" class="submission-form">
            <div
              v-if="!(taskData?.action_url || '').includes('?quest=ar') && !(taskData?.action_url || '').includes('?quest=cr') && !(taskData.action_url || '').includes('?quest=oc')"
              class="my-2">
              <strong style="font-size: 22px;" class="text-primary">Your Submission</strong>
            </div>
            <div>
              <div v-if="isInteractiveTask">
                <div v-for="(i, index) in requiredTemplateInputs.characterCount" :key="index" class="mt-1">
                  <ion-label class="bold" mode="md" for="`cpicker-${i}`">Character {{ i }}</ion-label>
                  <div class="my-2">
                    <div class="mt-2">
                      <div class="my-2">{{ selectedCharacterNames?.[index] }}</div>
                    </div>
                    <div class="d-flex align-items-center">
                      <ion-button @click="openCharSelectionModal(index)">Choose</ion-button>
                      <div class="close-icon text-black ml-4">
                        <i class="ti-close" />
                      </div>
                      <span class="text-black reset" :disabled="!newSubmission.extra?.characters?.[index]"
                        color="medium" @click="resetSelectedCharacter(index)">
                        <span class="no-select clickable-item">Reset</span>
                      </span>
                    </div>
                    <div class="mt-2">
                      <div class="my-2" v-if="selectedCharacterNames?.[index]">You picked <strong>{{
                        selectedCharacterNames?.[index] }}</strong></div>
                    </div>
                  </div>
                </div>
              </div>

              <ion-label v-if="isInteractiveTask" for="input-1">
                <span class="bold"> {{ userResponseFieldLabels?.[0] }}</span>
              </ion-label>
              <ion-textarea
                v-if="(taskData.response_type === 'T' && !isInteractiveTask) || (taskData.response_type === 'T' && isInteractiveTask && userResponseFieldLabels.length >= 1)"
                v-model="newSubmission.submission_text" rows="6" class="c-textarea"
                :placeholder="isInteractiveTask && userResponseFieldLabels?.length ? `${userResponseFieldLabels[0]}` : 'Your submission'" />

              <div v-if="taskData.response_type === 'C'">
                <div v-if="!isInteractiveTask">
                  <div class="d-flex align-items-center">
                    <ion-button @click="openCharSelectionModal(0)">Choose</ion-button>
                    <div class="close-icon text-black ml-4">
                      <i class="ti-close" />
                    </div>
                    <span class="text-black reset" :disabled="!newSubmission.submission_character" color="medium"
                      @click="resetNewSubmission">
                      <span class="no-select clickable-item">Reset</span>
                    </span>
                  </div>
                </div>

                <div v-if="newSubmission.submission_image_url">
                  <p>Your submission (Preview)</p>
                  <img loading="lazy" :src="newSubmission.submission_image_url" class="img-preview">
                </div>
              </div>

              <div
                v-if="(taskData.response_type === 'C' && !isInteractiveTask && !isEmpty(newSubmission.submission_character))">
                <Grid :scrollSm="false" :sm="2">
                  <CharacterCard :character="selectedCharacters[0]" class="grid-item" />
                </Grid>
              </div>

              <div v-if="isInteractiveTask && requiredTemplateInputs.userResponseCount">
                <div v-for="(i, index) in requiredTemplateInputs.userResponseCount - 1" :key="index" class="mt-2">
                  <ion-label :for="`input-${i + 1}`">
                    <span class="bold"> {{ userResponseFieldLabels?.[i] }}</span>
                  </ion-label>
                  <ion-textarea v-model="newSubmission.extra.user_responses[i - 1]" rows="6" class="c-textarea"
                    :placeholder="`${userResponseFieldLabels?.[i]}`" />
                </div>
              </div>
            </div>
            <div v-if="taskData.response_type === 'I'">
              <div class="d-flex mb-4 align-items-center">
                <UploadForm class="clickable-item" @uploaded="changeImage">
                  <ion-button class="uploader">
                    <i class="ti-import mr-1" style="transform: rotate(180deg);" />
                    Upload</ion-button>
                </UploadForm>
                <div class="close-icon text-black ml-4">
                  <i class="ti-close" />
                </div>
                <span class="text-black reset" :disabled="!newSubmission.submission_image_url" color="medium"
                  @click="resetNewSubmission">
                  <span class="no-select clickable-item">Reset</span>
                </span>
              </div>
              <div v-if="newSubmission.submission_image_url">
                <p>Your submission (Preview)</p>
                <img loading="lazy" :src="newSubmission.submission_image_url" class="img-preview">
              </div>
            </div>

            <br />
            <div v-if="taskData.is_response_publishable" class="pb-4">
              <p>
                <ion-checkbox v-model="newSubmission.is_public" class="no-select" label-placement="end">
                  <ion-label class="label">Let others see your response</ion-label></ion-checkbox>
              </p>
              <p>
              <div @click="() => (changeColor = true)">
                <ion-checkbox v-if="newSubmission.is_public" v-model="newSubmission.is_nsfw" class="no-select"
                  :disabled="!(user.is_nsfw || user.show_nsfw)" label-placement="end"><ion-label class="label">Mark
                    response as Mature
                    <a @click.stop target="_blank"
                      href="https://docs.google.com/document/d/1xSdAdkRj7n8BfJuz0KKPiM2IJTi8MiAVePruZqr4Gag/edit#heading=h.sqkkh44ifgqo">(see
                      content guidelines)</a></ion-label></ion-checkbox>

              </div>
              </p>
              <div v-if="!(user.is_nsfw || user.show_nsfw)" class="d-flex align-items-center p-2 my-2"
                style="height: 30px" :style="changeColor ? `background: #ffc409 !important` : ''">
                <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
                <p style="font-size: 10px; color: grey" :style="changeColor ? `font-weight: bold` : ''">
                  You must turn on “show mature content” and confirm you’re 18+ in
                  <router-link :style="changeColor ? `font-weight: bold` : ''" to="/account/user-settings"
                    target="_blank">
                    <strong class="clickable-item-hov">Content Settings</strong>
                  </router-link>
                  to share mature content
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <ion-button v-if="taskData && taskData.response_type !== 'A'"
                class="inline-button submit-button text-white" :disabled="submitDisabled" @click="onEventSubmission">
                <span class="no-select">Submit</span>
              </ion-button>
              <!-- <VerificationButton actionName="Submit" v-else-if="!get(user, 'is_email_verified', true)" /> -->
              <a v-else-if="!isCustomEvent && isAuthenticated" :href="taskData.action_url">
                <ion-button class="inline-button submit-button text-white w-100">
                  <span class="m-3 no-select">Start</span>
                </ion-button>
              </a>
            </div>
          </div>

          <div v-else-if="!isAuthenticated">
            <h5 class="d-flex align-items-center">
              <div @click="openSignUpModal" class="clickable-item-hov text-primary mr-1">Sign in </div>
              <div> to participate in quests and events!</div>
            </h5>
          </div>
        </div>
        <div v-if="isCustomEvent && isAuthenticated">
          <ion-button v-if="(taskData?.action_url || '').includes('?quest=cr')"
            class="inline-button submit-button text-white" @click="openCharacterRoulette">
            <span class="m-3 no-select">Start</span>
          </ion-button>
          <ion-button v-else-if="(taskData?.action_url || '').includes('?quest=ar')"
            class="inline-button submit-button text-white" @click="openArtRoulette">
            <span class="m-3 no-select">Start</span>
          </ion-button>
          <router-link v-else
            :to="(taskData.action_url || '').includes('?quest=oc') ? { name: 'home', query: { event: 'decoration-fest', id: taskData.id } } : taskData.action_url!">
            <ion-button class="inline-button submit-button text-white">
              <span class="m-3 no-select">Start</span>
            </ion-button>
          </router-link>
        </div>
        <div v-if="submissions.length > 0">
          <h4 class="bold">
            Your <span v-if="submissions.length === 1">submission</span><span v-else>submissions</span>
            <span>&nbsp;({{ submissions.length }}<span
                v-if="taskData.per_user_submission_limit && submissions.length <= taskData.per_user_submission_limit">
                of {{
                  taskData.per_user_submission_limit }} allowed</span>)</span>
            &nbsp;<ion-badge class="completed" color="tertiary" title="Done!"><i class="ti-check" /></ion-badge>
          </h4>
          <div v-if="!isCustomEvent">
            <div v-for="s, subIndex in submissions" :key="subIndex" class="mt-3">
              <p class="mt-3 mb-1"><span class="small gray">{{ formatTimeAgo(s.created) }}</span></p>
              <p
                v-if="submissionIsInteractionResponse(s) && (s.interaction_response || s.interaction_responses?.length)">
              <p v-if="s.extra?.characters?.length"><span class="bold">Characters: </span>{{
                formatCharacterList(matchingInteractionResponse(s, subIndex, false).characters_serialized) }}</p>
              <p class="bold">{{ getPastResponseFieldLabels(matchingInteractionResponse(s, subIndex,
                false).characters_serialized)[0] }}</p>
              <p v-for="(u, index) in s.extra?.user_responses" :key="index">
              <p class="bold">{{ getPastResponseFieldLabels(matchingInteractionResponse(s, subIndex,
                false).characters_serialized)[index + 1] }}
              </p>{{ u }}
              </p>
              <div v-if="matchingInteractionResponse(s, subIndex, false).form_response">
                <div class="q-response p-3">
                  <h5 class="bold mt-0">Quest Outcome</h5>{{ matchingInteractionResponse(s, subIndex,
                    false).form_response
                  }}
                </div>
              </div>
              <p v-else class="pt-2">
                <!-- Tell the user to come back later -->
                <vue-countdown v-slot="{ days, hours, minutes, seconds }"
                  :time="formatFromNowInMilliseconds(matchingInteractionResponse(s, subIndex, false).notify_at)"
                  @end="responseIsReady">
                  <span>
                    <span class="bold">You've done your part!&nbsp;</span><span>Come back in&nbsp;</span>
                    <span v-if="days">{{ days }} day<span v-if="days > 1">s</span></span>
                    <span v-if="hours"><span v-if="days">&nbsp;and&nbsp;</span>{{ hours }}<span
                        v-if="days">&nbsp;hour<span v-if="hours > 1">s</span></span></span>
                    <span v-if="!days"><span v-if="hours">:</span><span v-if="minutes < 10">0</span>{{ minutes }}</span>
                    <span v-if="!days">:<span v-if="seconds < 10">0</span>{{ seconds }}</span>&nbsp;to see what
                    happened.
                  </span>
                  <p
                    v-if="formatFromNowInMilliseconds(matchingInteractionResponse(s, subIndex, false).notify_at) <= 0 && !showResponse[subIndex]">
                    <span class="clickable-item-hov" @click="refreshData">Refresh</span> this page to see how things
                    went!<br>(If you have refreshed and still see this message, please contact us!)
                  </p>
                </vue-countdown>
              </p>
              </p>
              <span v-if="s.submission_text && s.submission_text !== 'Submitted'">{{ s.submission_text }}</span>
              <span v-else-if="s.submission_image_url"><img loading="lazy" class="img-preview"
                  :src="s.submission_image_url"></span>
              <div v-else-if="s.submission_characters">
                <Grid :scrollSm="false" :sm="2">
                  <CharacterCard v-for="sc in s.submission_characters" :character="sc" class="grid-item" />
                </Grid>
              </div>
            </div>
          </div>
          <div v-if="showFeedbackSection">
            <div v-if="askForFeedback === true" class="mt-2 w-100 request-feedback flex-column">
              <h6 class="bold">How disappointed would you be if we removed this type of quest?</h6>
              <FeedbackRating :task="taskData?.id" :range="5" label-min="Not disappointed at all"
                label-max="Very disappointed" @submit="onSubmittedFeedback" />
            </div>
            <div v-else-if="askForFeedback === false" class="mt-4">Thank you for your feedback!</div>
          </div>
        </div>
        <div v-if="previousSubmissions.length > 0">
          <h4 class="bold">
            Your Past Submissions<span>&nbsp;({{ previousSubmissions.length }})</span>
            <div class="mt-1"><ion-button @click="togglePreviousSubmissions">
                <span v-if="showingPreviousSubmissions">Hide</span>
                <span v-else>Show</span>
              </ion-button></div>
          </h4>
          <div v-if="!isCustomEvent && showingPreviousSubmissions">
            <div v-for="p, subIndex in previousSubmissions" :key="'p' + subIndex" class="mt-3">
              <p class="mt-3 mb-1"><span class="small gray">{{ formatTimeAgo(p.created) }}</span></p>
              <p v-if="submissionIsInteractionResponse(p) && (p.interaction_response || p.interaction_responses)">
              <p v-if="p.extra?.characters?.length"><span class="bold">Characters: </span>{{
                formatCharacterList(matchingInteractionResponse(p, subIndex, true).characters_serialized) }}</p>
              <p class="bold">{{ getPastResponseFieldLabels(matchingInteractionResponse(p, subIndex,
                true).characters_serialized)[0] }}</p>
              <p v-for="(u, index) in p.extra?.user_responses" :key="index">
              <p class="bold">{{ getPastResponseFieldLabels(matchingInteractionResponse(p, subIndex,
                true).characters_serialized)[index + 1] }}
              </p>{{ u }}
              </p>
              <div v-if="matchingInteractionResponse(p, subIndex, true).form_response">
                <div class="q-response p-3">
                  <h5 class="bold mt-0">Quest Outcome</h5>{{ matchingInteractionResponse(p, subIndex,
                    true).form_response }}
                </div>
              </div>
              <p v-else class="pt-2">
                <!-- Tell the user to come back later -->
                <vue-countdown v-slot="{ days, hours, minutes, seconds }"
                  :time="formatFromNowInMilliseconds(matchingInteractionResponse(p, subIndex, true).notify_at)"
                  @end="responseIsReady">
                  <span>
                    <span class="bold">You've done your part!&nbsp;</span><span>Come back in&nbsp;</span>
                    <span v-if="days">{{ days }} day<span v-if="days > 1">s</span></span>
                    <span v-if="hours"><span v-if="days">&nbsp;and&nbsp;</span>{{ hours }}<span
                        v-if="days">&nbsp;hour<span v-if="hours > 1">s</span></span></span>
                    <span v-if="!days"><span v-if="hours">:</span><span v-if="minutes < 10">0</span>{{ minutes }}</span>
                    <span v-if="!days">:<span v-if="seconds < 10">0</span>{{ seconds }}</span>&nbsp;to see what
                    happened.
                  </span>
                  <p
                    v-if="formatFromNowInMilliseconds(matchingInteractionResponse(p, subIndex, true).notify_at) <= 0 && !showResponse[subIndex]">
                    <span class="clickable-item-hov" @click="refreshData">Refresh</span> this page to see how things
                    went!<br>(If you have refreshed and still see this message, please contact us!)
                  </p>
                </vue-countdown>
              </p>
              </p>
              <span v-if="p.submission_text && p.submission_text !== 'Submitted'">{{ p.submission_text }}</span>
              <span v-else-if="p.submission_image_url"><img loading="lazy" class="img-preview"
                  :src="p.submission_image_url"></span>
              <div v-else-if="p.submission_characters">
                <Grid :scrollSm="false" :sm="2">
                  <CharacterCard v-for="sc in p.submission_characters" :character="sc" class="grid-item" />
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="taskData.is_response_publishable && (taskData.response_type === 'I' || taskData.response_type === 'T' || taskData.response_type === 'C')"
          class="public-submissions mt-2">

          <InfinitePublicSubmissions :task-id="taskData?.id" :submission-id-to-fetch="notifSubmission"
            @toggleHeader="togglePublicSubmissionHeader" />
        </div>
        <div class="footer-padding"></div>
        <InsertCharacterInfoFieldsModal v-if="isAuthenticated" header="We need more info for this quest!"
          :is-open="isInfoFieldModalOpen" :fields="emptyRequiredTemplateFields" :characters="selectedCharIds"
          @close="closeCharacterInfoFieldsModal" @saveAndClose="saveAndCloseCharacterInfoFieldsModal"
          @directSubmitClose="submitAndCloseCharacterInfoFieldsModal" />
        <CharacterRouletteModal v-if="isAuthenticated" :is-open="isCharacterRouletteOpen"
          :close="closeCharacterRoulette" @dismiss-modal="closeCharacterRoulette" />
        <ArtRouletteModal v-if="isAuthenticated" :is-open="isArtRouletteOpen" :close="closeArtRoulette"
          @dismiss-modal="closeArtRoulette" />
        <event-character-selection-modal v-if="isAuthenticated" :is-open="isSelectingCharModal"
          :index="isSelectingCharIndex" @selected="selectedEventChar" @close="closeCharSelectionModal" />
        <EventRulesModal :is-open="isOpenEventRulesModal" @close="closeAndOpenEventSubModal"
          @cancel="isOpenEventRulesModal = false" :isEvent="isSigningUpFromEventTask" :events="get(taskData, 'event')" />
        <EventSubmissionModal :is-open="isOpenEventSubmissionModal" @close="closeAndOpenEntryModal" />
        <ShareEventSubDetailsModal :is-open="isOpenEntryModal" :task="taskData" :submission="newSubmissionData"
          @close="closeAndOpenOnBoarding" @dismiss="dismissEventSubModal" />
        <SignupModal :is-open="isOpenSignUpModal" @close="isOpenSignUpModal = false"
          @signUp="openSiteEventTermsModal" />

      </ion-page>
    </div>
  </div>
</template>

<script lang="ts" setup>
import axios, { AxiosError } from 'axios';
import SignupModal from '~/shared/modals/SignupModal.vue';
import InfinitePublicSubmissions from './components/InfinitePublicSubmissionsPreview.vue';
import { toast } from '@/shared/native';
import CharacterRouletteModal from '@/shared/modals/CharacterRouletteModal.vue';
import EventCharacterSelectionModal from '@/shared/modals/CharacterSelectionModal.vue';
import ArtRouletteModal from '@/shared/modals/ArtRouletteModal.vue';
import { Character, SiteEventTask, SiteEventTaskSubmission } from '@/shared/types/static-types';
import {
  getEventTask,
  submitToEventTask,
  getUserTaskSubmissions,
  fetchHasSubmittedTaskFeedback,
  getsiteEventTermsAndCondition,
} from '@/shared/actions/events';
import { getUserCharacters } from '@/shared/actions/characters';
import { dateHasPassed, formatTimeAgo, formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import UploadForm from '@/shared/components/upload-form.vue';
import AddCharacterInfoField from '@/shared/components/AddCharacterInfoField.vue';
import FeedbackRating from '@/shared/components/FeedbackRating.vue';
import InsertCharacterInfoFieldsModal from '@/shared/modals/InsertCharacterInfoFieldsModal.vue';
import logger from '@/shared/services/logger';
import { authStore } from '@/shared/pinia-store/auth';
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/EventCharacterCard.vue';
import VerificationButton from '@/shared/components/VerificationButton.vue';
import { warningOutline } from 'ionicons/icons';
import EventRulesModal from '~/shared/modals/EventRulesModal.vue'
import EventSubmissionModal from '~/shared/modals/EventSubmissionModal.vue'
import ShareEventSubDetailsModal from '~/shared/modals/ShareEventSubDetailsModal.vue'
import { onboardingStore } from '~/shared/pinia-store/onboarding';
import { truncateEasy } from '@/shared/utils/string';
const { user, isAuthenticated } = authStore()
const { initOnboarding, ongoingOnboarding, onboarding } = onboardingStore();
const router = useRouter();
const route = useRoute()
const isCharacterRouletteOpen = ref(false);
const isArtRouletteOpen = ref(false);
const isSelectingCharModal = ref(false);
const isSelectingCharIndex: any = ref(0)
const selectedCharacters = ref([null]);
const selectedCharacterNames = ref([null]);
const showingPreviousSubmissions = ref(false);
const changeColor = ref(false);
const taskData = ref<SiteEventTask>({ id: '', name: '', user_submission_count: 0 });
const isLoading = ref(true)
const isOpenEventSubmissionModal = ref(false)
const isOpenEntryModal = ref(false)
const isOpenEventRulesModal = ref(false)
const termsAgreement = ref({}) as any
const submissions = ref([]) as Ref<SiteEventTaskSubmission[]>;
const previousSubmissions = ref([]) as Ref<SiteEventTaskSubmission[]>;
const newSubmission = ref<SiteEventTaskSubmission>({
  task: '',
  user: '',
  created: '',
  extra: {
    user_responses: [],
    characters: [],
    input_character_fields: {},
  },
  is_public: true,
  is_nsfw: false,
  username: '',
});
const newSubmissionData = ref({}) as any
const isOpenSignUpModal = ref(false)
const requiredTemplateInputs = computed(() => {
  let userResponseCount = 0;
  let characterCount = 0;
  // get number of user responses required
  taskData.value.required_template_fields?.forEach((field: string) => {
    if (field.startsWith('user_response')) {
      userResponseCount++;
    }
  });
  // get higher character number (equal to the number of characters required)
  taskData.value.required_template_fields?.forEach((field) => {
    const match = field.match(/character(\d+)_/);
    if (match) {
      const [, num] = match;
      try {
        characterCount = Math.max(characterCount, parseInt(num));
      } catch (e) { }
    }
  });
  return { userResponseCount, characterCount };
})



const closeAndOpenEntryModal = () => {
  isOpenEventSubmissionModal.value = false
  isOpenEntryModal.value = true
}
const closeAndOpenOnBoarding = () => {
  isOpenEntryModal.value = false
  resetNewSubmission();
  if (ongoingOnboarding.value && isEmpty(onboarding.value)) initOnboarding()
}
const dismissEventSubModal = (val: any) => {
  if (get(val, 'detail.role') === 'backdrop') {
    closeAndOpenOnBoarding()
  }
}

const resetSelectedCharacter = (index: number) => {
  newSubmission.value.extra.characters[index] = null;

  if (requiredTemplateInputs?.value.characterCount) {
    selectedCharacters.value = new Array(requiredTemplateInputs.value.characterCount).fill(null);
    selectedCharacterNames.value = new Array(requiredTemplateInputs.value.characterCount).fill(null);
  } else {
    selectedCharacters.value = [null];
    selectedCharacterNames.value = [null];
  }
}

const resetNewSubmission = () => {
  newSubmission.value = {
    task: '',
    user: '',
    created: '',
    extra: {
      user_responses: [],
      characters: [],
      input_character_fields: {},
    },
    is_public: true,
    is_nsfw: false,
    username: '',
  };
  newSubmissionData.value = {}
}

const isInfoFieldModalOpen = ref(false);
const taskStatus = ref('');
const userCharacters = ref([]) as Ref<Character[]>;
const showResponse = ref([]) as Ref<Boolean[]>;
const askForFeedback = ref(null) as any;
const requiredFields = ref([]);
const days = ref(0);
const hours = ref(12);
const minutes = ref(1);
const seconds = ref(1);
const notifSubmission = ref('');
const isSigningUpFromEventTask = ref(false)
const showPublicSubmissionHeader = ref(false);

const togglePublicSubmissionHeader = (show: boolean) => {
  showPublicSubmissionHeader.value = show;
}

const openCharacterInfoFieldsModal = () => {
  // format required fields for the modal
  isInfoFieldModalOpen.value = true;
}
const eventSignUp = () => {
  isSigningUpFromEventTask.value = false;
  router.push({ path: router.currentRoute.value.fullPath, query: { _: Date.now() } });
}
const openSiteEventTermsModal = () => {
  isOpenSignUpModal.value = false;
  if (!isEmpty(get(taskData.value, 'event.legal_terms'))) { 
    isSigningUpFromEventTask.value = true;
    isOpenEventRulesModal.value = true }
  else {
    eventSignUp()
  }
};

const togglePreviousSubmissions = () => {
  showingPreviousSubmissions.value = !showingPreviousSubmissions.value
}
const extractFirstTxt = computed(() => {
  if (taskData.value && taskData.value.event_title) {
    const trimmedTitle = taskData.value.event_title.trim();
    const firstTxt = trimmedTitle.split(' ')[0];
    return firstTxt ? firstTxt : trimmedTitle;
  }
  return '';
});

const openSignUpModal = () => {
  isOpenSignUpModal.value = true
}

const computeEndDate = () => {
  const nowDate = new Date();
  if ((taskData.value.action_url || '').includes('?quest=oc')) {
    const nowHours = nowDate.getHours();
    const nowMins = nowDate.getMinutes();

    const startDate = new Date(taskData.value.starts_at);
    const startHours = startDate.getHours();
    const startMins = startDate.getMinutes();

    let totalHours;

    if (nowHours < startHours) {
      totalHours = nowHours + (24 - startHours);
    } else {
      totalHours = nowHours - startHours;
    }

    const totalMinutes = nowMins - startMins + 60;

    const remainingHours = 24 - totalHours;
    const remainingMins = 60 - totalMinutes;

    nowDate.setHours(nowHours + remainingHours);
    nowDate.setMinutes(nowMins + remainingMins);

    return nowDate.toISOString();
  } else {
    return nowDate.toISOString();
  }
}

const showRefreshTimer = (task: SiteEventTask) => {
  return task.user_submission_count && task.will_refresh;
}

const computedEndsAt = () => {
  return (taskData.value.action_url || '').includes('?quest=oc') ? computeEndDate() : taskData.value.ends_at;
}

const closeCharacterInfoFieldsModal = (chars: Character[]) => {
  // update matching characters in this.userCharacters by id field
  if (chars?.length) {
    userCharacters.value = userCharacters.value.map((char: any) => {
      const matchingChar = chars.find((c) => c.id === char.id);
      if (matchingChar) {
        return matchingChar;
      }
      return char;
    });
  }
  isInfoFieldModalOpen.value = false;
}

const saveAndCloseCharacterInfoFieldsModal = (chars: Character[]) => {
  // update matching characters in this.userCharacters by id field
  if (chars?.length) {
    userCharacters.value = userCharacters.value.map((char) => {
      const matchingChar = chars.find((c) => c.id === char.id);
      if (matchingChar) {
        return matchingChar;
      }
      return char;
    });
  }
  isInfoFieldModalOpen.value = false;
  makeSubmission();
}

const submitAndCloseCharacterInfoFieldsModal = (chars: any) => {
  // update matching characters in this.userCharacters by id field
  if (chars) newSubmission.value.extra.input_character_fields = chars;
  isInfoFieldModalOpen.value = false;
  makeSubmission();
}

const matchingInteractionResponse = (submission: SiteEventTaskSubmission, offsetIndex: number, isPastIteration: boolean) => {
  if (submission.interaction_response) return submission.interaction_response;
  if (isPastIteration) return submission?.interaction_responses?.[offsetIndex + submissions.value.length];
  return submission?.interaction_responses?.[offsetIndex];
}

const currentTaskId = computed(() => {
  return router.currentRoute.value.params.id as string;
})


const _getCharacterById = (allCharacters: Character[], id: string) => {
  return find(allCharacters, { id });
}


const checkForEmptyRequiredTemplateFields = (characterArray: Character[]) => {
  const out = [] as any[];
  for (let i = 0; i < requiredTemplateInputs.value.characterCount; i++) {
    out.push([]);
  }
  if (!out.length) return [];
  taskData.value.required_template_fields?.forEach((field: any) => {
    const match = field.match(/character(\d+)_/);
    if (match) {
      const [, num] = match;
      try {
        // debugger;
        const characterIndex = parseInt(num) - 1;
        const fieldArray = field.split('__').slice(1);
        const cid = newSubmission.value.extra.characters[characterIndex];
        let val = _getCharacterById(characterArray, cid)?.info;
        if (val) {
          fieldArray.forEach((field: any) => {
            if (val) val = val[field];
          });
          if (!val) {
            out[characterIndex].push(fieldArray);
          }
        }
      } catch (e) {
        logger.error({
          e,
          loc: 'event-task',
          msg: 'Error in emptyRequiredTemplateFields',
          data: newSubmission.value,
        } as any);
      }
    }
  });
  return out;
}



const emptyRequiredTemplateFields = computed(() => {
  // result looks like
  // [[['backstory'], ['about', 'eyes'], ...], [['backstory'], ['about', 'eyes'], ...]]
  const out = checkForEmptyRequiredTemplateFields(userCharacters.value);
  return out;
})


const isInteractiveTask = computed(() => {
  return requiredTemplateInputs.value.userResponseCount || requiredTemplateInputs.value.characterCount;
})

const interactiveTaskRequiresMoreCharacterInfo = computed(() => {
  return emptyRequiredTemplateFields?.value.some((f: string[]) => f.length);
})

const showFeedbackSection = computed(() => {
  if (isInteractiveTask.value) {
    return submissions.value.some((sub) => sub?.interaction_response || sub?.interaction_responses?.[0].form_response);
  } else {
    return submissions.value.length;
  }
})




const recalcTime = () => {
  if (!taskData.value.ends_at) taskStatus.value = '';
  else if (dateHasPassed(taskData.value.ends_at)) taskStatus.value = 'expired';
  else if (dateHasPassed(taskData.value.starts_at)) taskStatus.value = 'ongoing';
  else taskStatus.value = 'upcoming';
}

const selectedCharIds = computed(() => {
  // get characters that are in userCharacters and filter out characters already in newSubmission.extra
  const x = userCharacters.value.filter((char) => {
    return newSubmission.value.extra.characters.includes(char.id);
  });
  x.sort((a, b) => {
    return newSubmission.value.extra.characters.indexOf(a.id) - newSubmission.value.extra.characters.indexOf(b.id);
  });
  return x;
})



const responseIsReady = (i: number) => {
  showResponse.value[i] = true;
}

const refreshData = () => {
  fetchTaskData();
  fetchUserTaskSubmissions();
}


const _fillCharacterNames = (allCharacters: Character[], characters: any[], str: string) => {
  const regex = new RegExp(`{character(\\d+)}`, 'g');
  return str.replace(regex, (_, digits) => {
    const index = parseInt(digits) - 1;
    return find(allCharacters, { id: characters[index] })?.info.name || `Character ${index + 1}`;
  });
}

const _getUserResponseField = (allCharacters: Character[], characters: any[], index: number) => {
  let out = `Field ${index + 1}`;
  const toFind = new RegExp(`{character(\d+)}`, 'g');
  try {
    out = taskData.value.required_template_field_labels.user_responses[index];
    out = _fillCharacterNames(allCharacters, characters, out);
  } catch (e) {
    out = out.replace(toFind, `Character ${index + 1}_`);
  } finally {
    return out;
  }
}

const openCharSelectionModal = (index?: number) => {
  isSelectingCharModal.value = true;
  isSelectingCharIndex.value = index;
}

const closeCharSelectionModal = () => {
  isSelectingCharModal.value = false;
  isSelectingCharIndex.value = undefined;
}

const selectedEventChar = (val: any) => {
  const { character, index } = val;
  selectedCharacters.value[index] = character;
  selectedCharacterNames.value[index] = character.info?.name;
  if (taskData.value.response_type === 'C') {
    newSubmission.value.submission_character = character.id;
  }
  if (isInteractiveTask.value && index !== undefined) {
    newSubmission.value.extra.characters[index] = character.id;
  }
  closeCharSelectionModal();
}

const userResponseFieldLabels = computed(() => {
  if (!taskData.value.required_template_field_labels?.user_responses?.length) return []; // if not interactive, return null
  const arr = Array(requiredTemplateInputs.value.userResponseCount)
    .fill(0)
    .map((_: number, index: number) =>
      _getUserResponseField(userCharacters.value, newSubmission.value.extra.characters, index)
    );
  return arr;
})



const getPastResponseFieldLabels = (characters: Character[]) => {
  const arr = Array(requiredTemplateInputs.value.userResponseCount)
    .fill(0)
    .map((_: number, index: number) =>
      _getUserResponseField(
        characters,
        characters.map((c) => c.id),
        index
      )
    );
  return arr;
}

const submissionIsInteractionResponse = (s: SiteEventTaskSubmission) => {
  return s.extra?.characters?.length || s.extra?.user_responses?.length;
}

const formatCharacterList = (characters: Character[]) => {
  return characters.map((c: any) => c.info.name).join(', ');
}

const fetchTaskData = async () => {
  try {
    taskData.value = await getEventTask(currentTaskId.value);
  } catch (e: any) {
    if (e.response.status === 404) {
      await toast.show('Page not found.', 'nonative', 'primary');
      return;
    }
    throw e;
  }
  // use userResponseCount-1 because the first user response is in submission_text
  if (requiredTemplateInputs.value.userResponseCount)
    newSubmission.value.extra.user_responses = new Array(requiredTemplateInputs.value.userResponseCount - 1).fill('');
  newSubmission.value.extra.characters = requiredTemplateInputs.value.characterCount
    ? new Array(requiredTemplateInputs.value.characterCount).fill('')
    : [];
  if (requiredTemplateInputs.value.characterCount && isAuthenticated.value) {
    userCharacters.value = await getUserCharacters(user?.value.id, null);
    selectedCharacters.value = new Array(requiredTemplateInputs.value.characterCount).fill(null);
    selectedCharacterNames.value = new Array(requiredTemplateInputs.value.characterCount).fill(null);
  } else {
    selectedCharacters.value = [null];
    selectedCharacterNames.value = [null];
  }
  recalcTime();

}

const fetchUserTaskSubmissions = async () => {
  const [currentSubmissions, previousSubmissions] = await Promise.all([
    getUserTaskSubmissions(currentTaskId.value),
    getUserTaskSubmissions(currentTaskId.value, true)
  ]);
  const filteredPreviousSubmissions = previousSubmissions.filter(
    (prevSub: any) => !currentSubmissions.some((currSub: any) => currSub.id === prevSub.id)
  );
  submissions.value = currentSubmissions;
  previousSubmissions.value = filteredPreviousSubmissions;
  showResponse.value = submissions.value.map(
    (sub: any) => !!sub.interaction_response || !!sub.interaction_responses?.some((z: any) => !!z.form_response)
  );
  if (submissions.value.length) fetchUserHasSubmittedFeedback();
}

const fetchUserHasSubmittedFeedback = async () => {
  const hasSubmittedFeedback = await fetchHasSubmittedTaskFeedback(currentTaskId.value);
  askForFeedback.value = !hasSubmittedFeedback;
}

const changeImage = (url: string) => {
  newSubmission.value.submission_image_url = url;
}

const isCustomEvent = computed(() => {
  if (taskData?.value.action_url?.includes('?quest=')) return true;
  return false;
})


const submitDisabled = computed(() => {
  if (!isAuthenticated.value) return true;
  return (
    taskData.value.per_user_submission_limit && taskData.value.per_user_submission_limit <= submissions.value.length
  );
})



const updateCharacter = (character: Character) => {
  // update the character in the userCharacters array
  userCharacters.value = userCharacters.value.map((c: Character) => {
    if (c?.id === character?.id) {
      return character;
    }
    return c;
  });
}

const openFillRequiredFieldsModal = () => {
  isInfoFieldModalOpen.value = true;
}

const closeAndOpenEventSubModal = () => {
  isOpenEventRulesModal.value = false;
  fetchEventTermsAndConditions()
  if (isSigningUpFromEventTask.value)  eventSignUp() 
  else makeSubmission()
}

const makeSubmission = async () => {
  if (!taskData?.value.id) return;
  if (requiredTemplateInputs.value.characterCount) {
    if (newSubmission.value.extra.characters.some((c: string) => !c)) {
      await toast.show('Please select the required number of characters.', 'nonative', 'danger');
      return;
    }
  }
  if (requiredTemplateInputs.value.userResponseCount) {
    if (newSubmission.value.extra.user_responses.some((r: string) => !r || !r.trim())) {
      await toast.show('All the submission fields need to be filled.', 'nonative', 'danger');
      return;
    }
    newSubmission.value.extra.user_responses = newSubmission.value.extra.user_responses.map((r: string) => r.trim());
  }
  const tempUpdatedCharacterArray = cloneDeep(userCharacters.value);
  for (let i = 0; i < newSubmission.value.extra.characters.length; i++) {
    const cid = newSubmission.value.extra.characters[i];
    const char = _getCharacterById(tempUpdatedCharacterArray, cid);
    if (char) {
      merge(char.info, newSubmission.value.extra.input_character_fields[cid]);
    }
  }
  if (checkForEmptyRequiredTemplateFields(tempUpdatedCharacterArray)?.some((f: string[]) => f.length)) {
    isInfoFieldModalOpen.value = true;
    return;
  }
  if (
    taskData.value.response_type === 'T' &&
    isInteractiveTask.value &&
    requiredTemplateInputs.value.userResponseCount === 0
  ) {
    newSubmission.value.submission_text = 'Submitted';
  }
  if (taskData.value.response_type === 'T' && newSubmission.value.submission_text) {
    newSubmission.value.submission_text = newSubmission.value.submission_text.trim();
  }
  if (taskData.value.response_type === 'T' && !newSubmission.value.submission_text) {
    await toast.show('Submission requires text', 'nonative', 'danger');
    return;
  }
  if (taskData.value.response_type === 'C' && isEmpty(newSubmission.value.submission_character)) {
    await toast.show('Choose character for submission', 'nonative', 'danger');
    return;
  }
  if (taskData.value.response_type === 'I' && !newSubmission.value.submission_image_url) {
    await toast.show('Submission requires an image upload', 'nonative', 'danger');
    return;
  }

  const lim = taskData.value.per_user_submission_limit;
  if (lim && submissions.value.length >= lim) {
    await toast.show('You have already made your submission(s) for this quest.', 'nonative', 'tertiary');
    return;
  }
  try {
    const data = await submitToEventTask(currentTaskId.value, newSubmission.value);
    newSubmissionData.value = data
    let message = 'Submission Complete!';
    if (data.is_reviewed) {
      if (data.points_given === 0) {
        message = `Submission complete!`;
      } else {
        message = `Submission complete! Reward has been added.`;
      }
    } else {
      message = `Submission complete! Your response will be reviewed soon.`;
    }
    toast.show(message, 'nonative', 'success');
    isOpenEventSubmissionModal.value = true
  } catch (e: unknown) {
    logger.error({
      e,
      loc: 'event-task',
      msg: 'Error in event-task.vue makeSubmission()',
      data: { task: taskData.value, submission: newSubmission.value },
    });
    if (axios.isAxiosError(e)) {
      const eAxiosError = e as AxiosError;
      if ((eAxiosError && !eAxiosError.response) || (eAxiosError.response && !eAxiosError.response.status)) {
        await toast.show('Encountered a connection issue', 'nonative', 'danger');
      }
      if (typeof eAxiosError.response?.data === 'string') {
        toast.show(eAxiosError.response?.data, 'nonative', 'danger');
      } else if (typeof eAxiosError.response?.data === 'object') {
        Object.keys(eAxiosError.response?.data || {}).forEach((key) => {
          toast.show((eAxiosError.response?.data || ({} as any))[key], 'nonative', 'danger');
        });
      } else {
        toast.show('Encountered an unexpected error', 'nonative', 'danger');
      }
    } else {
      toast.show('Encountered an unexpected error', 'nonative', 'danger');
    }
    return;
  }
  fetchUserTaskSubmissions();

}

const onEventSubmission = async () => {
  !isEmpty(get(taskData.value, 'event.legal_terms')) && !termsAgreement.value.agreement ? isOpenEventRulesModal.value = true : makeSubmission()
}
const onSubmittedFeedback = () => {
  askForFeedback.value = false;
}

const openCharacterRoulette = () => {
  isCharacterRouletteOpen.value = true;
}

const openArtRoulette = () => {
  isArtRouletteOpen.value = true;
}

const closeCharacterRoulette = () => {
  isCharacterRouletteOpen.value = false;
}

const closeArtRoulette = () => {
  isArtRouletteOpen.value = false;
}
const fetchEventTermsAndConditions = async () => {
  const id = get(taskData.value, 'event.id') as any
  const resp = await getsiteEventTermsAndCondition(id)
  termsAgreement.value = resp
  isLoading.value = false

}
watch(taskData, () => {
  if (taskData.value && isAuthenticated.value) fetchEventTermsAndConditions()
  else isLoading.value = false
})
onMounted(() => {
  const document = useDocument();
  document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  fetchTaskData();
  if (isAuthenticated.value) fetchUserTaskSubmissions();
  notifSubmission.value = (route.query.s as string) || '';
})

</script>
<style lang="sass" scoped>
.label
  white-space: break-spaces
.event-task-preview
  background: white
  border-radius: 12px
.dark .event-task-preview
  background: #17074c
.quest-sec
  min-width: 30%
  max-width: 30%
  @media(max-width: 675px)
    min-width: 38%
    max-width: 38%


.quest-title
  color: white
  font-size: 32px
  font-weight: bolder
  @media(max-width: 550px)
    font-size: 22px

.task-title
  color: white
  font-size: 18px
  font-weight: 600
  @media(max-width: 550px)
    font-size: 14px

.events-preview
  @media(min-width:850px)
    max-width: 800px
    margin-left: auto !important
    margin-right: auto !important
  @media(max-width:1600px) and (min-width:1400px)
    max-width: 600px !important
  @media(max-width:1400px) and (min-width:1185px)
    max-width: 500px !important
.submit-button
  --background: linear-gradient(90deg, rgba(126, 224, 255, 0.4) 0%, rgba(95, 169, 238, 0) 100%), #A83CFF
  --border-radius: 11px
  border-radius: 9px
  margin: 0px
  font-size: 22px
  font-weight: bold
  height: 48px
  width: 25%
  @media(max-width:500px)
    width: 50%
  
.uploader
  --border-radius: 10px
     
.close-icon
  font-size: 8px
  border: 2px solid
  border-radius: 50px
  padding: 3px
  aspect-ratio: 1/1
  height: 17px
  width: 17px
  
.icon
  margin-right: 0.5rem
  @media(max-width:419px)
    font-size: 9px
    margin-left: 0.25rem !important
    margin-right: 0.25rem !important
  
.end-btn
  height: 25px
  --border-radius: 20px
  text-transform: none
  font-size: 13px
.prompts
  font: bolder 210px "Roboto Slab", sans-serif
  -webkit-text-fill-color: rgb(255 255 255 / 15%)
  font-weight: bolder
  text-transform: uppercase

.slide
  width: 100%
  max-height: 200px
  min-height: 200px
  object-fit: cover
  background: linear-gradient(90deg, rgba(126, 224, 255, 0.4) 19.53%, rgba(95, 169, 238, 0) 35%, rgba(126, 224, 255, 0.4) 100%), #A83CFF
  
.slide-bg
  background-size: cover
  opacity: 0.3
  height: 100%
  
.slide-img
  object-fit: contain
  z-index: 99
  height: 170px
  margin: 1rem
  @media(max-width: 515px)
    width: 46%
    margin: 0.5rem
    height: 148px
    
.clickable-item:hover
  opacity: 0.7
.title
  font-weight: bold
  font-size: 22px
.submission-form
  margin-bottom: 1rem
.no-select
  user-select: none !important
.prewrap-icon
  border: 3px solid var(--ion-color-primary)
  color: var(--ion-color-primary)
  border-radius: 5px
  padding: 2px
  font-size: 13px
  margin-right: 0.5rem
  @media(max-width:419px)
    font-size: 12px
    margin-left: 0.25rem !important
    margin-right: 0.25rem !important
.prewrap
  white-space: pre-wrap
  border: 2px solid var(--ion-color-primary)
  border-radius: 12px
  padding: 10px
.img-preview
  max-width: 200px
  max-height: 200px
.reset
  margin-left: 5px
  font-weight: bold
  text-decoration: underline
.completed
  position: relative
  left: 1px
  top: 5px
.cpicker
  max-width: 300px
.clickable-item-hov
  color: var(--ion-color-primary) !important
.event-clr
  color: #AE38E5 !important
.q-response
  white-space: pre-wrap
  word-wrap: break-word
  background: rgba(0, 0, 0, 0.05)
  border-radius: 10px
.c-textarea
  border-radius: 10px !important
  border: 2px solid #CCC !important
.request-feedback
  display: flex
  justify-content: center
  align-items: center
  ion-button
      width: 150px
.footer-padding
  height: 75px
  </style>